






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InsCmsPhotoSwiper: () => import('@/components/business/cms/InsCmsPhotoSwiper.vue')
  }
})
export default class InsCurrentTab0 extends Vue {
  @Prop() private foldData!:object;
  showPhotoList: boolean = false;
  photoIndex: number = 0;
  cmsBannerList: object[] = [];
  page: string = '';
  position: number = 0;

  // 点击某张相片
  clickOne (i) {
    this.showPhotoList = true;
    this.photoIndex = i;
  }

  showSwiper (sta) {
    this.showPhotoList = sta;
  }
  getPromotion () {
    switch (this.id) {
      case '40156':
        this.page = 'Award';
        this.position = 3;
        break;
      case '40147':
        this.page = '60th';
        this.position = 5;
        break;
    }
    this.$Api.promotion.GetPromotionByDevice({ Page: this.page, Position: this.position, IsMobile: this.isMobile, BannerEnable: true }).then((result) => {
      this.cmsBannerList = result.Promotion.BannerList;
      console.log(this.cmsBannerList, 'cms内文相册2');
    }).catch((error) => {
      console.log(error, 'error');
    });
  }
  get id () {
    return this.$route.params.id;
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }
  mounted () {
    this.getPromotion();
  }
  @Watch('id', { deep: true })
  onKeyChange () {
    this.getPromotion();
    console.log(this.foldData, '传过来的foldData-0');
  }
}
